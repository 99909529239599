<template>
  <div class="relative">
    <search-panel-desktop class="search" @blur="onBlurEvent" @focus="onFocusEvent" :class="[ isSearchClosed ? 'closed-search' : 'open-search' ]" />
  </div>
</template>

<script>
import SearchIcon from '@vue-storefront/core/compatibility/components/blocks/Header/SearchIcon'
const SearchPanelDesktop = () => import(/* webpackChunkName: "vsf-search-panel-desktop" */ 'theme/components/core/blocks/SearchPanel/SearchPanelDesktop.vue')
export default {
  data () {
    return {
      isSearchClosed: true
    }
  },
  mixins: [SearchIcon],
  components: {
    SearchPanelDesktop
  },
  methods: {
    onBlurEvent (e) {
      if (!e) {
        this.isSearchClosed = true
      }
    },
    onFocusEvent (e) {
      this.isSearchClosed = false
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  transition: 0.3s all ease;
  top: 5px;
  right: 5px;
  position: absolute;
  &.open-search {
    width: 200px;
  }
  &.closed-search {
    @media screen and (min-width: 1350px){
      width: 200px;
    }
    width: 35px;
  }
}
</style>
