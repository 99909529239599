<template>
  <button
    type="button"
    class="relative bg-cl-transparent brdr-none flex flex-col middle-xs"
    :class="isMicrocart ? 'cl-main' : 'cl-light-gray'"
    @click="openMicrocart"
    data-testid="openMicrocart"
    :aria-label="$t('Open microcart')"
  >
    <i class="icon-cart h4" />
    <span class="visible-md fs11 sans-serif">Koszyk</span>
    <span
      class="minicart-count absolute flex center-xs middle-xs border-box py0 px2 h6 lh16 weight-700 cl-white bg-cl-main"
      v-cloak
      v-show="totalQuantity"
      data-testid="minicartCount"
    >
      {{ totalQuantity }}
    </span>
  </button>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MicrocartIcon from '@vue-storefront/core/compatibility/components/blocks/Header/MicrocartIcon'
import { syncCartWhenLocalStorageChange } from '@vue-storefront/core/modules/cart/helpers'

export default {
  mounted () {
    syncCartWhenLocalStorageChange.addEventListener()
    this.$once('hook:beforeDestroy', () => {
      syncCartWhenLocalStorageChange.removeEventListener()
    })
  },
  computed: {
    ...mapState({
      isMicrocart: state => state.ui.microcart,
      isAuthElem: state => state.ui.isAuthElem
    }),
    ...mapGetters({
      totalQuantity: 'cart/getItemsTotalQuantity'
    })
  },
  methods: {
    openMicrocart () {
      if (this.isAuthElem) {
        this.$bus.$emit('modal-hide', 'modal-signup')
      }
      this.$store.dispatch('ui/toggleMicrocart')
    }
  }
}
</script>

<style scoped>
  .minicart-count {
    top: 7px;
    left: 50%;
    min-width: 16px;
    min-height: 16px;
    border-radius: 10px;
  }
  .fs11 {
    font-size: 11px
  }
</style>
