<template>
  <router-link
    @click.native="closeAllModals()"
    :to="localizedRoute('/')"
    :title="$t('Home Page')"
    class="no-underline flex flex-col middle-xs"
    :class="[routeName === 'home' ? 'cl-main' : 'cl-light-gray']"
  >
    <i class="icon-home h4" />
    <span class="visible-md fs11">Home</span>
  </router-link>
</template>

<script>
export default {
  computed: {
    routeName () {
      return this.$route.name
    }
  },
  methods: {
    closeAllModals () {
      this.$store.commit('ui/setOverlay', false)
      this.$store.commit('ui/clearAllModals')
    }
  }
}
</script>

<style scoped>
.fs11 {
  font-size: 11px;
}
</style>
