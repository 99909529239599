<template>
  <div class="baner-carousel align-center h6 uppercase py5 cl-white">
    <hooper
      :auto-play="true"
      :play-speed="2000"
      :infinite-scroll="true"
      :mouse-drag="false"
    >
      <slide v-for="(label, index) in infoData" :key="index">
        {{ label.text }}
      </slide>
      <hooper-navigation slot="hooper-addons" class="hidden-xs" />
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'BanerCarousel',
  components: {
    Hooper,
    Slide,
    HooperNavigation
  },
  props: {
  },
  data () {
    return {
      isLoad: false
    }
  },
  mounted () {
    if (!this.validBaners['infoTopBar']) {
      this.$store.dispatch('cmsBlock/list', { filterValues: null })
    }
  },
  computed: {
    validBaners () {
      return this.$store.state.cmsBlock.baners
    },
    infoData () {
      if (this.validBaners['infoTopBar'] && this.validBaners['infoTopBar'].length && this.validBaners['infoTopBar'][0]) {
        return this.validBaners['infoTopBar'][0]
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="scss">
.baner-carousel {
  .hooper {
    height: auto;
    .hooper-next {
      right: -35px;
      filter: brightness(0) invert(1);
    }
    .hooper-prev {
      left: -35px;
      filter: brightness(0) invert(1);
    }
  }
}
</style>

<style lang="scss" scoped>
.baner-carousel {
  @media (min-width: 768px) {
    width: 368px;
    margin: 0 auto;
  }
}
</style>
