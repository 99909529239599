<template>
  <nav class="megamenu row" @mouseleave="activeId = null">
    <div class="relative" v-for="id in visibleCategories" :key="id + 'mega-menu'">
      <div
        v-if="menuCategoriesById[id]"
        @mouseenter="hoverCategory(id)"
        class="menu__item pointer flex"
      >
        <router-link
          :to="categoryLink(menuCategoriesById[id][0])"
          class="no-underline cl-black animated-dot-left px15 py25"
        >
          {{ menuCategoriesById[id][0].name }}
        </router-link>
      </div>
      <div
        v-else
        @mouseenter="activeId = null"
        class="menu__item pointer flex"
      >
        <router-link
          :to="localizedRoute({ path: id.path })"
          class="no-underline cl-black animated-dot-left px15 py25"
        >
          {{ id.name }}
        </router-link>
      </div>
    </div>
    <ul class="megamenu__submenu absolute pointer" v-if="activeId && sortedCategoriesMap[activeId]">
      <li
        v-for="subcategory in sortedCategoriesMap[activeId]"
        :key="subcategory.id"
      >
        <router-link
          :to="categoryLink(subcategory)"
          class="no-underline left-dot-animation"
          :class="{'uppercase': subcategory.name === 'Lego®' || subcategory.name === 'Elle' || subcategory.name === 'Elle Decoration' || subcategory.name === 'Elle Man'}"
        >
          {{ subcategory.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import config from 'config'
import { mapGetters } from 'vuex'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import groupBy from 'lodash-es/groupBy'

export default {
  data () {
    return {
      activeId: null
    }
  },
  computed: {
    ...mapGetters('category-next', ['getMenuCategories']),
    categoriesMap () {
      return groupBy(this.getMenuCategories, 'parent_id')
    },
    menuCategoriesById () {
      return groupBy(this.getMenuCategories, 'id')
    },
    sortedCategoriesMap () {
      let arr = this.categoriesMap
      for (let key in arr) {
        if (key !== '2') {
          arr[key].sort(this.cmpNames)
        }
      }
      return arr
    },
    visibleCategories () {
      return config.server.megaMenuCategories
    }
  },
  methods: {
    cmpNames (a, b) {
      return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
    },
    hoverCategory (id) {
      this.activeId = id
      this.$store.dispatch('category-next/fetchMenuCategories', { parent: id })
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    }
  }
}
</script>

<style lang="scss" scoped>

.megamenu {
    align-items: center;
    @media (min-width: 1025px) {
      display: flex;
      flex-direction: row;
      -ms-flex-direction: row;
    }
    .menu__item {
        @media (max-width: 1024px) {
          padding-left: 10px;
          padding-right: 10px;
        }
        text-align: center;
        justify-content: center;
        font-size: 14px;
    }
}

.animated-dot-left {
  max-width: 100px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 10px;
    height: 10px !important;
    bottom: calc(50% - 5px);
    left: calc(15px);
    opacity: 0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    pointer-events: none;
    background-color: #1396EC;
    transition: 0.3s ease;
  }

  &:hover:after {
    opacity: 1;
    left: -5%;
  }
}

.left-dot-animation {
  left: 0;
  position: relative;
  transition: 0.5s;
  @media (hover: hover) {
    &:hover {
      transition: 0.5s;
      left: 15px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 10px;
    height: 10px;
    bottom: calc(50% - 5px);
    left: -15px;
    opacity: 0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: #1396EC;
    transition: 0.5s;
  }
  &.active:before,
  &:hover:before {
    opacity: 1;
  }
}

.megamenu__submenu {
    // display: grid;
    // grid-auto-flow: column;
    // grid-template-rows: repeat(10, 0.7fr);
    width: 45%;
    // align-items: center;
    background-color: #fff;
    top: 89px;
    padding: 30px;
    list-style: none;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    // flex version
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    max-height: 300px;
    @media (min-width: 767px) and (max-width: 1200px) {
      width: 65%;
    }
    li {
      margin: 0px 15px 10px 0px;
    }
    li a {
        color: #000;
        text-decoration: none;
        font-size: 12px;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 20px;
        width: 100%;
        height: 4px;
        background-color: #1396EC;
    }
}
</style>
