<template>
  <button
    type="button"
    class="flex flex-col middle-xs bg-cl-transparent brdr-none relative"
    :class="isWishlist ? 'cl-main' : 'cl-light-gray'"
    @click="toggleWishlistPanelCustom"
    data-testid="wishlist-icon"
    :aria-label="$t('Open wishlist')"
  >
    <i class="icon-favourite-fill h4" />
    <span class="visible-md fs11 sans-serif">Ulubione</span>
    <span
      class="whishlist-count absolute flex center-xs middle-xs border-box py0 px2 h6 lh16 weight-700 cl-white bg-cl-main"
      v-cloak
      v-show="getWishlistItemsCount"
    >
      {{ getWishlistItemsCount }}
    </span>
  </button>
</template>

<script>
import { mapState } from 'vuex'
import WishlistIcon from '@vue-storefront/core/compatibility/components/blocks/Header/WishlistIcon'

export default {
  mixins: [WishlistIcon],
  computed: {
    ...mapState({
      isWishlist: state => state.ui.wishlist,
      isAuthElem: state => state.ui.isAuthElem
    })
  },
  methods: {
    toggleWishlistPanelCustom () {
      if (this.isAuthElem) {
        this.$bus.$emit('modal-hide', 'modal-signup')
      }
      this.$store.dispatch('ui/toggleWishlist')
    }
  }
}
</script>

<style scoped>
  .whishlist-count {
    top: 7px;
    left: 50%;
    min-width: 16px;
    min-height: 16px;
    border-radius: 10px;
  }
  .fs11 {
  font-size: 11px;
  }
</style>
